var domain = document.location.protocol + '//' + document.location.hostname;

$(function(){
  
  // open raw xml mode in new tab on ctrl+alt+r
  var url = window.location + '';
  if (location.href.split(location.host)[1].indexOf('/tangelo/page/') == 0) {
    $(window).keypress(function(e){
      var code = e.keyCode || e.which;
      if(code == 174 && e.ctrlKey && e.altKey) {
        var urlBase = url.split('?')[0];
        var urlParams = url.split('?')[1] ? '?' + url.split('?')[1] : '';
        var newUrl = urlBase + '/raw' + urlParams;
        window.open(newUrl,'_blank');
      }
    });
  }
	
  // website exit
  $.expr[':'].external = function (a) {
    return a.href.indexOf('http') == 0 && a.href.indexOf(domain) != 0;
  };
  $('a:external').each(function(){
    $(this).attr('target','_blank');
  });
  
  // build email links (spam prevention)
	$('a.email').each(function(){
		var pre       = $(this).data('pre');
		var after     = $(this).data('after');
		var textPre   = $(this).data('text-pre');
		var textAfter = $(this).data('text-after');
		$(this).attr('href', 'mailto:' + pre + '@' + after);
		if (textPre) $(this).text(textPre + '@' + textAfter);
	});
  
	/* left/right placement of tooltips */
	if ($('.content')[0]) {
  	var leftPosContent = $('.content').offset().left;
  	var widthContent   = $('.content').width();
  	$('.hint--bottom').each(function(){
  	  var leftPos = $(this).offset().left;
  	  if (widthContent / 2 < leftPos - leftPosContent) $(this).addClass('hint--leftside');
  	});
	}
	
	/* collapse and popup large tables */
	$('.table-zoom')
	.each(function(){
	  var minMargin   = 250;
	  var zoombutton  = $(this);
	  var table       = zoombutton.parent();
	  var tableHeight = table.height();
	  var positionTop = tableHeight > 600 ? minMargin : tableHeight / 2;
	  
	  zoombutton.css('top',positionTop+'px');
	  
	  if (tableHeight > 600) {
   	  var tableTop = table.offset().top;
      $(window).scroll(function(){
        var tableScrollPos = $(this).scrollTop() - tableTop;
   	    if (tableScrollPos > 0 && tableScrollPos < tableHeight - tableTop - minMargin) {
     	    positionTop = minMargin + tableScrollPos;
     	    zoombutton.css('top',positionTop+'px');
   	    }
   	  });
   	}
	})
	.click(function(){
	  $(this).parent().addClass('popup');
	})
	;
	
	$('.table-close').click(function(){
	  $(this).parent().removeClass('popup');
	});
	
	/* fix left menu on page scroll */
  $(window).scroll(function(){
    var navHeight  = $('.sidebar-left').height();
    var pageHeight = $('.content').height();
    var maxMT      = pageHeight - navHeight - 80;
    if ($(window).height() > navHeight + 20 && pageHeight > navHeight && pageHeight > 1000) {
      var scrollPos = $(this).scrollTop();
      var margintop = scrollPos > 240 ? scrollPos - 240 : 0;
      margintop     = margintop > maxMT ? maxMT : margintop;
      $('.sidebar-left').css('margin-top',margintop);
    }
  });

  $('.navigation-item-popup').click(function (e) {
    e.preventDefault();
    if (Cookies.get('seen-' + $(this).data("target"))) {
      window.location.href = $(this).attr("href");
    }
    else {
      const target = ".link-" + $(this).data('target');
      $(target).addClass("active");
      $('body').addClass("noscroll");
    }
  });
  
  $('.modal-button.cancel').click(function () {
    if (!$(this).parents('.modal-link-confirm.this-page').length) {
      $(this).parents('.modal-link-confirm').removeClass('active');
      $('body').removeClass("noscroll");
    }
  });
  
  $('.modal-button.confirm').click(function (e) {
    e.preventDefault();
    Cookies.set('seen-' + $(this).data("target"), true, { sameSite: 'strict', expires: 1 });
    window.location.href = $(this).attr("href");
  });
  
  const thisPagePopup = $('.modal-link-confirm.this-page');
  if (thisPagePopup && !Cookies.get('seen-' + thisPagePopup.find('.modal-button.confirm').data("target"))) {
    thisPagePopup.addClass("active");
  }

});